<template>
  <div v-if="!showModal">
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <!-- 搜索栏 -->
        <a-row>
			<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
				<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" allow-clear
					style="width: 180px;">
					<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
						{{ item.name }}</a-select-option>
				</a-select>
			</a-form-item>
          <!-- <a-form-item label="影院名称" name="cinemaName" class="ui-form__item">
            <a-input v-model:value="formState.cinemaName" placeholder="请输入影院名称"></a-input>
          </a-form-item> -->

          <a-form-item label="影片名称" name="filmName" class="ui-form__item">
            <a-input v-model:value="formState.filmName" placeholder="请输入影片名称"></a-input>
          </a-form-item>

          <!-- <a-form-item label="用户ID" name="userID" class="ui-form__item">
            <a-input v-model:value="formState.userID" placeholder="请输入用户ID"></a-input>
          </a-form-item> -->

          <a-form-item label="用户信息" name="userInfo" class="ui-form__item">
            <a-input v-model:value="formState.userInfo" placeholder="请输入用户信息"></a-input>
          </a-form-item>
        </a-row>

        <a-row v-show="showAll">
          <a-form-item label="影片编码" name="filmCode" class="ui-form__item">
            <a-input v-model:value="formState.filmCode" placeholder="请输入影片编码"></a-input>
          </a-form-item>

          <a-form-item label="座位" name="seatName" class="ui-form__item">
            <a-input v-model:value="formState.seatName" placeholder="请输入座位"></a-input>
          </a-form-item>

          <a-form-item label="订单号" name="orderNo" class="ui-form__item">
            <a-input v-model:value="formState.orderNo" placeholder="请输入订单号"></a-input>
          </a-form-item>

          <!-- <a-form-item label="取票状态" name="ticketStatus" class="ui-form__item">
            <a-select v-model:value="formState.ticketStatus" placeholder="请选择取票状态" allow-clear style="width: 195px;">
              <a-select-option :value="1">未取票</a-select-option>
              <a-select-option :value="2">取票中</a-select-option>
              <a-select-option :value="3">已取票</a-select-option>
            </a-select>
          </a-form-item> -->

			<a-form-item label="取票状态" name="printStatusList" class="ui-form__item">
			  <a-select mode="multiple" v-model:value="formState.printStatusList" placeholder="请选择取票状态" allow-clear style="width: 195px;">
			    <a-select-option :value="0">未取票</a-select-option>
			    <a-select-option :value="1">已取票</a-select-option>
			    <a-select-option :value="2">已退票</a-select-option>
			    <a-select-option :value="3">已改签</a-select-option>
			  </a-select>
			</a-form-item>

          <a-form-item label="优惠方式" name="discountType" class="ui-form__item">
            <a-select v-model:value="formState.discountType" placeholder="请选择优惠方式" allow-clear style="width: 195px;">
              <a-select-option :value="1">优惠券</a-select-option>
              <a-select-option :value="2">一卡通</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="出票渠道" name="dataSource" class="ui-form__item">
            <a-select v-model:value="formState.dataSource" placeholder="请选择出票渠道" allow-clear style="width: 195px;">
              <a-select-option :value="2">凤凰云智</a-select-option>
              <a-select-option :value="3">辰星云3.0</a-select-option>
              <a-select-option :value="4">辰星云4.0</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="支付方式" name="payMessageType" class="ui-form__item">
            <a-select v-model:value="formState.payMessageType" placeholder="请选择支付方式" allow-clear style="width: 195px;">
              <a-select-option :value="'wx_lite'">微信支付</a-select-option>
              <!-- <a-select-option :value="'alipay'">支付宝支付</a-select-option> -->
              <a-select-option :value="'card'">一卡通支付</a-select-option>
              <a-select-option :value="'zero'">0元支付</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="是否售后中" name="isAfterSale" class="ui-form__item">
            <a-select v-model:value="formState.isAfterSale" placeholder="请选择售后状态" allow-clear style="width: 195px;">
              <!-- <a-select-option :value="0">否</a-select-option> -->
              <a-select-option :value="1">售后中</a-select-option>
              <a-select-option :value="2">改签中</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="订单状态" name="status" class="ui-form__item">
            <a-select v-model:value="formState.status" placeholder="请选择订单状态" allow-clear style="width: 195px;">
              <a-select-option :value="'CHECKOUT_SUCCESS'">待使用</a-select-option>
              <a-select-option :value="'SYSTEM_CANCE'">系统超时取消</a-select-option>
              <a-select-option :value="'CUSTOMER_CANCEL'">用户自行取消</a-select-option>
			  <a-select-option :value="'WAIT_BUYER_PAY'">待付款</a-select-option>
			  <a-select-option :value="'WAIT_DIVIDE_SEAT'">待出票</a-select-option>
			  <a-select-option :value="'WAIT_CHECK'">待核销</a-select-option>
			  <a-select-option :value="'EXPIRED'">已过期</a-select-option>
			  <a-select-option :value="'WAIT_COMMENT'">待评价</a-select-option>
			  <a-select-option :value="'TRADE_FINISH'">订单完成</a-select-option>
			  <a-select-option :value="'AFTERSALE_FINISH'">改签/售后完成</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="购买时间"  name="createTime" class="ui-form__item">
            <a-range-picker v-model:value="formState.createTime"></a-range-picker>
          </a-form-item>

          <a-form-item label="放映时间"  name="showTime" class="ui-form__item">
            <a-range-picker v-model:value="formState.showTime"></a-range-picker>
          </a-form-item>
        </a-row>

        <a-row>
          <a style="margin-bottom: 10px" @click="showAll = !showAll">
            {{ showAll ? '收起' :'展开' }}
            <Icon v-show="!showAll" icon="DownOutlined"></Icon>
            <Icon v-show="showAll" icon="UpOutlined"></Icon>
          </a>
        </a-row>

        <a-row>
          <a-col :span="18">
            <span v-permission="['reportForms_sale_filmOrder_export']">
				<ExportReport type="cinemaOrderExportStrategy" :searchData="searchData"></ExportReport>
			</span>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
                style="margin-right: 10px"
                type="primary"
                html-type="submit"
            >搜索</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>

      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
                 :scroll="{ x: 4950 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'userInfo'">
              <div>用户昵称：{{ record.nickname ? record.nickname : '-' }}</div>
              <div>手机号：{{ record.phone ? record.phone : '-' }}</div>
            </template>
            <template v-if="column.key === 'createTime'">
              {{ transDateTime(record.createTime) }}
            </template>
            <template v-if="column.key === 'showStartTime'">
              {{ transDateTime(record.showStartTime) }}
            </template>
            <template v-if="column.key === 'seatNum'">
              {{ record.orderItemList ? record.orderItemList.length : '-' }}
            </template>
            <template v-if="column.key === 'seatName'">
              <div v-for="item in record.orderItemList" :key="item.id">
                {{ item.seatInfoDetail?.rowCode }}排
                {{ item.seatInfoDetail?.columnCode }}座
                <span v-if="item.seatInfoDetail?.partitionName"> --- {{ item.seatInfoDetail?.partitionName }}</span>
              </div>
            </template>
            <template v-if="column.key === 'dataSourceServe'">
              <div>增值服务费：{{ orderItemCount(record, 'ticketFee') }}</div>
              <div>平台服务费：{{ orderItemCount(record, 'serviceFee') }}</div>
            </template>
            <template v-if="column.key === 'serviceCharge'">
              {{ orderItemCount(record, 'serviceCharge')}}
            </template>
			<template v-if="column.key === 'payMessageType'">
				<div v-if="record.payMessageType === 'card'">一卡通支付</div>
				<div v-if="record.payMessageType === 'wx_lite'">微信支付</div>
				<div v-if="record.payMessageType === 'zero'">0元支付</div>
			</template>
            <template v-if="column.key === 'couponType'">
              <div v-if="record.couponType === 1">代金券</div>
              <div v-if="record.couponType === 2">抵扣券</div>
            </template>
            <template v-if="column.key === 'printTime'">
              {{ transDateTime(record.printTime, 1) }}
            </template>
            <template v-if="column.key === 'status'">
				{{ record.isAfterSale === 0 ? transStatus(record.status) : ['', '售后待审核', '改签中'][record.isAfterSale] }}
              <!-- <div v-if="record.status === 'CHECKOUT_SUCCESS'">已付款</div> -->
              <!-- <div v-if="record.status === 'TRADE_FINISH'">已取票</div> -->
              <!-- <div v-if="record.status === 'AFTERSALE_FINISH'">全部售后</div> -->
            </template>
			<template v-if="column.key === 'ticketStatus'">
				{{ ['-', '未取票', '取票中', '已取票'][record.ticketStatus] }}
			</template>
            <template v-if="column.key === 'dataSource'">
              <div v-if="record.dataSource === 1">凤凰佳影</div>
              <div v-if="record.dataSource === 2">凤凰云智</div>
              <div v-if="record.dataSource === 3">辰星云3.0</div>
              <div v-if="record.dataSource === 4">辰星云4.0</div>
            </template>
            <template v-if="column.key === 'action'">
              <a-button v-permission="['reportForms_sale_filmOrder_detail']" @click="onShowSeatInfo(record)" type="link" size="small">订单座位信息</a-button>
            </template>
          </template>
        </a-table>
      </div>

      <!--  订单座位信息  -->
      <a-modal v-model:visible="showInfoModal" title="订单座位信息" width="1000px">
		<a-spin :spinning="loading">
        <div>
          <a-descriptions>
            <a-descriptions-item label="订单号">{{ data.orderNo ? data.orderNo : '-' }}</a-descriptions-item>
            <a-descriptions-item label="影院">{{ data.cinemaName ? data.cinemaName : '-' }}</a-descriptions-item>
            <a-descriptions-item label="影片">{{ data.filmName ? data.filmName : '-' }}</a-descriptions-item>
            <a-descriptions-item label="用户ID">{{ data.userId ? data.userId : '-' }}</a-descriptions-item>
            <a-descriptions-item label="放映时间">{{ transDateTime(data.showStartTime) }} - {{ transDateTime(data.showEndTime) }}</a-descriptions-item>
          </a-descriptions>
          <a-descriptions>
            <a-descriptions-item label="退票服务费" v-if="[1, 2].includes(data.orderItemList[0].afterSaleStatus)">{{ data.afterSaleTotalServiceFee ? data.afterSaleTotalServiceFee : '-' }}</a-descriptions-item>
            <a-descriptions-item label="退款金额" v-if="[2].includes(data.orderItemList[0].afterSaleStatus)">{{ data.afterSaleTotalRefund ? data.afterSaleTotalRefund : '-' }}</a-descriptions-item>
            <a-descriptions-item label="改签服务费" v-if="[4].includes(data.orderItemList[0].afterSaleStatus)">{{ data.changeTotalServiceFee ? data.changeTotalServiceFee : '-' }}</a-descriptions-item>
          </a-descriptions>
        </div>
        <div style="margin-top: 20px;">
          <a-table :pagination="false" :columns="seatInfoColumns" :dataSource="seatInfoList" rowKey="id"
                   :scroll="{ x: 500 }">
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'refundUserType'">
                <div v-if="record.refundUserType === 1">小程序</div>
                <div v-if="record.refundUserType === 2">后台</div>
                <div v-if="record.refundUserType === 3">柜台</div>
              </template>
              <template v-if="column.key === 'afterSaleStatus'">
                <div v-if="record.afterSaleStatus === 0">未发起</div>
                <div v-if="record.afterSaleStatus === 1">售后中</div>
                <div v-if="record.afterSaleStatus === 2">售后完成</div>
                <div v-if="record.afterSaleStatus === 3">改签中</div>
                <div v-if="record.afterSaleStatus === 4">改签完成</div>
              </template>
              <template v-if="column.key === 'refundCreateTime'">
                {{ transDateTime(record.refundCreateTime) }}
              </template>
              <template v-if="column.key === 'refundAuditTime'">
                {{ transDateTime(record.refundAuditTime) }}
              </template>
              <template v-if="column.key === 'refundPriceStatus'">
                <div v-if="record.refundPriceStatus === 0">无需退款</div>
                <div v-if="record.refundPriceStatus === 1">退款中</div>
                <div v-if="record.refundPriceStatus === 2">退款成功</div>
                <div v-if="record.refundPriceStatus === 3">退款失败</div>
              </template>
            </template>
          </a-table>
        </div>
		</a-spin>
        <template #footer>
          <a-button @click="showInfoModal = false" v-if="[0, 2].includes(data.orderItemList[0].afterSaleStatus)">取消</a-button>
          <a-button v-permission="['reportForms_sale_filmOrder_refuse']" @click="showRejectModal = true" v-if="[1].includes(data.orderItemList[0].afterSaleStatus)">拒绝</a-button>
          <a-button v-permission="['reportForms_sale_filmOrder_return']" @click="showAfterModal = true" type="primary" v-if="[0].includes(data.orderItemList[0].afterSaleStatus)">全部售后</a-button>
          <a-button v-permission="['reportForms_sale_filmOrder_agree']" @click="onSubmit" type="primary" v-if="[1].includes(data.orderItemList[0].afterSaleStatus)">同意</a-button>
          <a-button type="primary" @click="showInfoModal = false" v-if="[4, 2].includes(data.orderItemList[0].afterSaleStatus)">确认</a-button>
        </template>
      </a-modal>

      <!--  确认拒绝  -->
      <a-modal v-model:visible="showRejectModal" title="确认拒绝" width="400px" style="margin-top: 100px;" @ok="onRejectOk">
		<a-spin :spinning="loading">
        <a-form-item label="拒绝售后备注信息">
          <a-input v-model:value="remark" placeholder="请输入拒绝售后备注信息"></a-input>
        </a-form-item>
		</a-spin>
      </a-modal>

      <!--  确认售后  -->
      <a-modal v-model:visible="showAfterModal" title="确认售后" width="500px" style="margin-top: 100px;" @ok="onAfterOk">
		<a-spin :spinning="loading">
        <div style="text-align: center">确认要发起该订单退款吗？退款后该订单座位将重新变回可选状态</div>
        <a-descriptions style="margin: 20px 50px 0">
          <a-descriptions-item :span="3" label="退款金额">{{ data.afterSaleTotalRefund ? data.afterSaleTotalRefund : '-' }}</a-descriptions-item>
          <a-descriptions-item :span="3" label="退款座位">
            <div><a-tag color="blue" v-for="item in data.orderItemList" :key="item.id">{{ item.seatName }}</a-tag></div>
          </a-descriptions-item>
        </a-descriptions>
		</a-spin>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import {
  Icon
} from '@/components/icon/icon.js';
import ExportReport from '@/components/exportReport/exportReport.vue';
import {
		getCinemaAllList
	} from '@/service/modules/cinema.js';
import {
  cinemaOrderAfterSale,
  getCinemaOrderDetail,
  getCinemaOrderList,
  ticketAgree,
  ticketReject
} from "@/service/modules/report";
export default {
  components: {
    Icon,
    ExportReport,
  },
  data() {
    return {
      remark: '',
      cinemaName: '',
      showModal: false,
      showInfoModal: false,
      showAll: false,
      isEdit: false,
      isSee: false,
      id: 0,
      loading: false,
	  cinemaAllList: [],
      searchData: {},
      modelRef: {},
      formState: {},
      showAfterModal: false,
      showRejectModal: false,
      data: {},
      seatInfoList: [],
      seatInfoColumns: [{
        title: "票号",
        dataIndex: "ticketNo",
      }, {
        title: "座位",
        dataIndex: "seatName",
      }, {
        title: "售后用户类型",
        key: "refundUserType",
      }, {
        title: "售后状态",
        key: "afterSaleStatus",
      }, {
        title: "发起时间",
        key: "refundCreateTime",
      }, {
        title: "完成时间",
        key: "refundAuditTime",
      }, {
        title: "退款状态",
        key: "refundPriceStatus",
      }],
      columns: [{
        title: '订单编号',
        dataIndex: 'orderNo',
      },
	  // {
   //      title: '用户ID',
   //      dataIndex: 'userId',
   //    },
	  {
        title: '用户信息',
        key: 'userInfo',
      }, {
        title: '购买时间',
        key: 'createTime',
      }, {
        title: '影片编码',
        dataIndex: 'filmCode',
      }, {
        title: '影片名称',
        dataIndex: 'filmName',
      }, {
        title: '放映时间',
        key: 'showStartTime',
      }, {
        title: '影院名称',
        dataIndex: 'cinemaName',
      }, {
        title: '影厅名称',
        dataIndex: 'hallName',
      }, {
        title: '座位数量',
        key: 'seatNum',
      }, {
        title: '座位',
        key: 'seatName',
      }, {
        title: '售票系统服务费',
        key: 'dataSourceServe',
      }, {
        title: '网络代售费',
        key: 'serviceCharge',
      }, {
        title: '优惠券',
        // key: 'couponType',
		dataIndex: 'couponName'
      }, {
        title: '优惠券优惠',
        dataIndex: 'couponDeduction',
      }, {
        title: '一卡通优惠',
        dataIndex: 'cardDiscountDeduction',
      }, {
        title: '应付金额',
        dataIndex: 'totalFee',
      }, {
        title: '实付金额',
        dataIndex: 'payment',
      }, {
		  title: '支付方式',
		  key: 'payMessageType',
		  width: 120
	  }, {
		  title: '取票状态',
		  key: 'ticketStatus'
	  }, {
        title: '取票时间',
        key: 'printTime',
      }, {
        title: '退款服务费',
        dataIndex: 'afterSaleTotalServiceFee',
      }, {
		title: '改签服务费',
		dataIndex: 'changeTotalServiceFee'
	  }, {
        title: '原订单',
        dataIndex: 'changeOrderNo',
      }, {
        title: '出票渠道',
        key: 'dataSource',
      }, {
        title: "订单状态",
        key: "status",
      }, {
        title: '操作',
        key: 'action',
        fixed: 'right',
		width: 150,
		align: 'center'
      }],
      list: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
	  this.getAllCinemaList();
    this.getData();
  },
  computed: {
    serviceFee() {
      let serviceFeeList = [];
      this.list.forEach(item => {
        let num = 0;
		if (item.orderItemList) {
			item.orderItemList.forEach(i => {
			  num += i.seatInfoDetail.ticketFee;
			})
		}
        serviceFeeList.push(num);
      })
      return serviceFeeList;
    }
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.formState.printStatusList = [];
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    async getData() {
      if(this.formState.createTime && this.formState.createTime.length === 2) {
        this.searchData.createStartTime = this.moment(this.formState.createTime[0].startOf('day')).unix();
        this.searchData.createEndTime = this.moment(this.formState.createTime[1].endOf('day')).unix();
      }
      delete this.searchData.createTime;
      if(this.formState.showTime && this.formState.showTime.length === 2) {
        this.searchData.showStartTime = this.moment(this.formState.showTime[0].startOf('day')).unix();
        this.searchData.showEndTime = this.moment(this.formState.showTime[1].endOf('day')).unix();
      }
      delete this.searchData.showTime;
      this.loading = true;
      try {
        let ret = await getCinemaOrderList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
	async getAllCinemaList() {
		this.cinemaAllList = [];
		let ret = await getCinemaAllList({});
		if (ret.code === 200) {
			this.cinemaAllList = ret.data;
		}
	},
    onShowSeatInfo(item) {
      this.id = item.id;
      this.getCinemaOrderDetail();
    },
    async onAfterOk() {
      this.loading = true;
      try {
        let ret = await cinemaOrderAfterSale({
          orderId: this.data.id,
        })
        this.loading = false;
        if(ret.code === 200) {
          this.$message.success('操作成功');
          this.showAfterModal = false;
          this.getCinemaOrderDetail();
        }
      } catch(e) {
        this.loading = false;
      }
    },
    async onSubmit() {
      this.loading = true;
      try {
        let ret = await ticketAgree({
          id: this.data.orderAfterSaleId,
        })
        this.loading = false;
        if(ret.code === 200) {
          this.$message.success('操作成功');
          this.getCinemaOrderDetail();
        }
      } catch(e) {
        this.loading = false;
      }
    },
    async onRejectOk() {
      this.loading = true;
      try {
        let ret = await ticketReject({
          id: this.data.orderAfterSaleId,
          remark: this.remark,
        })
        this.loading = false;
        if(ret.code === 200) {
          this.$message.success('操作成功');
          this.getCinemaOrderDetail();
        }
      } catch(e) {
        this.loading = false;
      }
    },
    async getCinemaOrderDetail() {
      this.loading = true;
      try {
        let ret = await getCinemaOrderDetail({
          orderId: this.id
        })
        this.loading = false;
        if(ret.code === 200) {
          this.data = ret.data;
          this.seatInfoList = ret.data.orderItemList;
          this.showInfoModal = true;
        }
      } catch(e) {
        this.loading = false;
      }
    },
	orderItemCount(record, key) {
		let num = 0;
		if (record.orderItemList) {
			record.orderItemList.forEach(item => {
				num += (item.seatInfoDetail[key] || 0)
			})
		}
		return num;
	},
	transStatus(status) {
		if (status === 'CHECKOUT_SUCCESS') {
			return '待使用'
		} else if (status === 'SYSTEM_CANCEL') {
			return '系统超时取消'
		} else if (status === 'CUSTOMER_CANCEL') {
			return '用户自行取消'
		} else if (status === 'WAIT_BUYER_PAY') {
			return '待付款'
		} else if (status === 'WAIT_DIVIDE_SEAT') {
			return '待出票'
		} else if (status === 'WAIT_SELLER_SEND_GOODS') {
			return '待发货'
		} else if (status === 'WAIT_CHECK') {
			return '待核销'
		} else if (status === 'WAIT_BUYER_CONFIRM_GOODS') {
			return '待确认收货'
		} else if (status === 'EXPIRED') {
			return '已过期'
		} else if (status === 'TRADE_FINISH') {
			return '订单完成'
		} else if (status === 'AFTERSALE_FINISH') {
			return '改签/售后完成';
		} else if (status === 'WAIT_COMMENT') {
			return '待评价'
		}
	}
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
